import { Fragment, memo } from "react";

//react-bootstrap
import { Nav, Tab } from "react-bootstrap";

//react-router-dom
import { Link, useNavigate } from "react-router-dom";


//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { imageUrlCheck, convertMinutesToHoursAndMinutes, translateText } from "../../utils/utils";


// Redux Selector / Action
import { useSelector } from "react-redux";
import Translate from "../MultiLanguage/Translate";
const TabSlider = memo(({ sliderData }) => {
  // const themeSchemeDirection = useSelector(theme_scheme_direction);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }
  const themeSchemeDirection = '';
  const navigate = useNavigate()
  return (
    <Fragment>
      <div className="tab-slider section-padding-top bottom_space">
        <div className="slider">
          <Swiper
            key={themeSchemeDirection}
            dir={themeSchemeDirection}
            watchSlidesProgress={true}
            className="position-relative swiper swiper-card"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            slidesPerView={1}
            modules={[Navigation, Pagination]}
          >
            {sliderData?.shows?.map((data) => (
              <SwiperSlide
                tag="li"
                className="tab-slider-banner"
                key={data.image}
              >
                <div className="tab-slider-banner-images">
                  {
                    imageUrlCheck(data?.logo || data?.banner) === true ? (
                      <img src={data?.banner || data?.logo || data?.logo_thumb || data?.banner} onError={(e) => replaceImage(e)} alt="Banner1" loading="lazy" />


                    ) : (
                      <img src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner || data?.logo}`} onError={(e) => replaceImage(e)} alt="Banner1" loading="lazy" />

                    )
                  }
                </div>
                <div className="block-images position-relative w-100">
                  <div className="container-fluid">
                    <div className="row align-items-center min-vh-100 h-100 my-4">
                      <div className="col-md-5 col-lg-5 col-xxl-5">
                        <div className="tab-left-details">
                          <div className="d-flex align-items-center gap-3 mb-4">
                            <Link to="">
                              <img
                                src="/assets/images/movies/trending-label.webp"
                                className="img-fluid"
                                alt=""
                              />

                            </Link>
                            <h5 className="text-gold">
                              {/* <span className="text-primary">#4</span>{" "} */}
                              {data.duration_text}
                            </h5>
                          </div>
                          <h1 className="mb-2 fw-500 text-capitalize texture-text">
                            {data.show_name}
                          </h1>
                          <p className="mb-0 font-size-14 line-count-2">
                            {data?.synopsis}
                          </p>
                          <ul className='d-flex align-items-center list-inline gap-2 movie-tag p-0 mt-3 mb-40 flex-wrap'>
                            {data?.categories?.length > 0 && data?.categories?.map((category, index) => {
                             return  <li className="font-size-18 text-white cursor-pointer "><Link className="text-white " to={category?.key ? `/category/${category.key}?career=${category.key}` : null}>{category?.category_name}</Link></li>
                            })}
                            {/* <li className="font-size-18">{}</li>
                            <li className="font-size-18 text-white">{data?.rating}</li> */}
                          </ul>
                          <div className="iq-button ">
                            <Link
                              to={`/show-details/${data?.vanity_url}`}
                              className="btn text-uppercase position-relative px-4 py-3"
                            >
                              <span className="button-text"><Translate textKey={'play_now'} /></span>
                              <i className="fa-solid fa-play"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1 col-lg-2 col-xxl-3 swipper_button_mobile"></div>
                      <div className="col-md-6 col-lg-5 col-xxl-4 mt-lg-5 mt-2 mt-md-0">
                        <div className="tab-block">
                          <h4 className="tab-title text-capitalize mb-0">
                          <Translate textKey={'all_episodes'} />
                          </h4>
                          <div className="tab-bottom-bordered border-0 trending-custom-tab">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="0season"
                            >
                              <Nav
                                variant="pills"
                                className="nav nav-tabs nav-pills mb-3 overflow-x-scroll overflow-y-scroll scrollhidden "
                              >
                                {data?.videos?.map((episodes, index) => (
                                  <Nav.Item key={"nav" + index}>
                                    <Nav.Link eventKey={index + "season"} >
                                    {episodes?.season}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                              <Tab.Content className="tab-content trending-content">
                                {data?.videos?.map((episodes, index) => (
                                  <Tab.Pane
                                    eventKey={index + "season"}
                                    key={index + "season"}
                                  >
                                    <ul className="list-inline m-0 p-0">
                                      {episodes?.videos?.map((episode) => (
                                        <li
                                          className="d-flex align-items-center gap-3 cursor-pointer"
                                          key={episode?.video_title + "ep"}
                                          onClick={()=>{
                                            navigate(`/videos/${episode?.vanity_url}`)
                                          }}
                                        >
                                          <div className="image-box flex-shrink-0">
                                            <img
                                              src={episode.thumbnail_350_200}
                                              alt="image-icon"
                                              className="img-fluid rounded"
                                            />
                                          </div>
                                          <div className="image-details">
                                            <h6 className="mb-1 text-capitalize">
                                              {/* <span className="text-primary">
                                                E{index + 1}
                                              </span>{" "} */}
                                              {/* -  */}
                                              {episode.video_title}
                                            </h6>
                                            {/* <small>45 minute</small> */}
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </Tab.Pane>
                                ))}
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="joint-arrows">
            <div className="swiper-button swiper-button-next swipper_button_mobile"></div>
            <div className="swiper-button swiper-button-prev swipper_button_mobile"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
})

TabSlider.displayName = "TabSlider"
export default TabSlider
